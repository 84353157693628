import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_ADMIN_CENTER, API_ATHLET, API_DISCOUNT, API_EVENT, API_LEAGUE, API_PAYMENT } from '../helpers/api_constant';
import { CURRENT_DATE, CURRENT_TIME, handleError } from '../helpers/utils';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  private DTDeleteEventData = new BehaviorSubject<any[]>([]);
  private paymentPlanDateLabelStore = new BehaviorSubject<string>('');
  private resetRegisterEventDetailsStore = new BehaviorSubject<string>('');
  DTeventDates$ = this.DTDeleteEventData.asObservable();
  paymentPlanDateLabel$ = this.paymentPlanDateLabelStore.asObservable();
  resetRegisterEventDetails$ = this.resetRegisterEventDetailsStore.asObservable();

  updateDTDeleteEventData(eventDates: any[]) {
    this.DTDeleteEventData.next(eventDates);
  }

  updatePaymentPlanDateLabel(dateLabel: string) {
    this.paymentPlanDateLabelStore.next(dateLabel);
  }

  resetRegisterEventDetails(value: string) {
    this.resetRegisterEventDetailsStore.next(value);
  }

  createEvent(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EVENT.EVENT}`, payload)?.pipe(catchError(handleError))
  }

  // get camp list
  getAllCampsList(payload: any): Observable<any> {
    const params = new HttpParams()
      // .set('isActive', payload.isActive)
      .set('orgId', payload.orgId)
      .set('startDate', payload.startDate ?? '')
      .set('type', payload.type ?? '')
      .set('limit', payload.limit)
      .set('skip', 0)
      .set('pastEvents', payload.pastEvents ?? '')

    const options = { params: params };

    return this.http.get(environment.API + API_EVENT.EVENT, options)?.pipe(catchError(handleError));
  }

  getEventById(id: any, orgId?: any) {
    return this.http.get<any>(`${environment.API}${API_EVENT.EVENT}/` + id + `?orgId=${orgId}&date=${CURRENT_DATE}&time=${CURRENT_TIME}&timezone=EST`)?.pipe(catchError(handleError));
  }

  getEventLocations(payload: { orgIds: any; skip: number; limit: number; isActive: boolean }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_EVENT.EVENT_LOCATION}?orgIds=${payload?.orgIds}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`)?.pipe(catchError(handleError))
  }

  createEventLocation(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EVENT.EVENT_LOCATION}`, payload)?.pipe(catchError(handleError))
  }

  updateEvent(payload: any): Observable<any> {
    return this.http.put<any>(`${environment.API}${API_EVENT.EVENT}`, payload)?.pipe(catchError(handleError))
  }

  eventPaymentIntent(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EVENT.EVENT_INTENT}`, payload)?.pipe(catchError(handleError))
  }

  teamPaymentIntent(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.LEAGUE_INTENT}`, payload)?.pipe(catchError(handleError))
  }

  eventRegister(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EVENT.EVENT_REGISTER}`, payload)?.pipe(catchError(handleError))
  }

  teamRegister(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.LEAGUE_PAYMENT}`, payload)?.pipe(catchError(handleError))
  }

  checkEventRegister(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EVENT.CHECK_EVENT_REGISTER}`, payload)?.pipe(catchError(handleError))
  }

  getAthleteByOrgId(id: any) {
    return this.http.get<any>(`${environment.API}${API_ATHLET.ATHLET_USER}/` + id)?.pipe(catchError(handleError));
  }

  getEventInsurance(eventId: number | string, applyInsurance: boolean, allowGapInsurance: boolean, athleteId?: any, discountCode?: string, usedPackage?: boolean, sketchplayBucks?: number | string, isRegisterByAdmin?: boolean) {
    return this.http.get<any>(`${environment.API}${API_EVENT.EVENT_INSURANCE}/${eventId}?allowInsurance=${applyInsurance}&allowGapInsurance=${allowGapInsurance}&athleteId=${athleteId ?? ''}&discountCode=${discountCode ?? ''}&usedPackage=${usedPackage}&sketchplayBucks=${sketchplayBucks ?? ''}&isRegisterByAdmin=${isRegisterByAdmin}&date=${CURRENT_DATE}&time=${CURRENT_TIME}&timezone=EST`)?.pipe(catchError(handleError));
  }

  getAllEventTypes() {
    return this.http.get<any>(`${environment.API}${API_EVENT.GET_EVENT_TYPE}`)?.pipe(catchError(handleError));
  }

  getDailyTrainingSprintList(payload: any): Observable<any> {
    const params = new HttpParams()
      .set('orgId', payload.orgId)
      .set('eventSprint', payload.eventSprint ? payload.eventSprint : '')
    // .set('startDate', format(new Date(), 'yyyy-MM-dd'))

    const options = { params: params };

    return this.http.get(environment.API + `${API_EVENT.EVENT_DAILY_TRAINING}?date=${CURRENT_DATE}&time=${CURRENT_TIME}&timezone=EST`, options)?.pipe(catchError(handleError));
  }

  getEventSchedule(payload: { orgId: number | string; }) {
    return this.http.get<any>(`${environment.API}${API_ADMIN_CENTER.EVENT_SPRINT}?orgId=${payload?.orgId}&offset=0&limit=1000`)?.pipe(catchError(handleError));
  }

  getAtleteUserList(id: any) {
    return this.http.get<any>(`${environment.API}${API_EVENT.EVENT_ATHLET_LIST}/${id}?date=${CURRENT_DATE}&time=${CURRENT_TIME}&timezone=EST`)?.pipe(catchError(handleError));
  }
  deleteEvent(id: any) {
    return this.http.delete<any>(`${environment.API}${API_EVENT.EVENT}/` + id)?.pipe(catchError(handleError));
  }
  checkInCheckOut(payload: any, status?: any) {
    if (status === 'CHECKEDIN') {
      return this.http.put<any>(`${environment.API}${API_EVENT.EVENT_UN_REGISTER_USER}`, payload)?.pipe(catchError(handleError));
    } else {
      return this.http.put<any>(`${environment.API}${API_EVENT.EVENT_USER_CHECK_IN}`, payload)?.pipe(catchError(handleError));
    }
  }
  updateDailyTrainingEvent(payload: any): Observable<any> {
    return this.http.put<any>(`${environment.API}${API_EVENT.UPDATE_DAILT_TRAINING}`, payload)?.pipe(catchError(handleError));
  }
  unregisterUSer(payload: any) {
    return this.http.put<any>(`${environment.API}${API_EVENT.EVENT_UN_REGISTER_USER}`, payload)?.pipe(catchError(handleError));
  }

  teamRegistrationInLeague(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_LEAGUE.REGISTER_TEAM}`, payload)?.pipe(catchError(handleError))
  }

  getTeamPaymentData(leagueId: number, teamId: number): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_LEAGUE.TEAM_PAYMENT}/${leagueId}/${teamId}`)?.pipe(catchError(handleError))
  }
  getEventDiscount(payload: { orgIds: number | string, skip: number, limit: number }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_DISCOUNT.DISCOUNT}?orgIds=${payload?.orgIds}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=true`)?.pipe(catchError(handleError));
  }

  checkDiscount(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_DISCOUNT.CHECK_DISCOUNT}`, payload)?.pipe(catchError(handleError))
  }

  eventRegisterFreePayment(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EVENT.EVENT_REGISTER_FREE_PAYMENT}`, payload)?.pipe(catchError(handleError))
  }

  checkPaymentPlanCalculation(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_PAYMENT.CHECK_PAYMENT_PLAN_CALCULATION}`, payload)?.pipe(catchError(handleError))
  }

  eventPlansPaymentIntent(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EVENT.EVENT_INTENT_PLAN}`, payload)?.pipe(catchError(handleError))
  }

  eventPlansCompletePayment(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EVENT.EVENT_REGISTER_PAYMENT_PLAN}`, payload)?.pipe(catchError(handleError))
  }

  deleteEventMultiple(ids: any) {
    return this.http.delete<any>(`${environment.API}${API_EVENT.DELETE_BULK_EVENT}?ids=${ids}`)?.pipe(catchError(handleError));
  }

  checkLacrosseNumber(num: number): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_EVENT.CHECK_LACROSSE_NUMBER}?lacrosseId=${num}`)?.pipe(catchError(handleError))
  }

  getAllSubEvents(id: number, orgId: number): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_EVENT.ALL_SUB_EVENTS}/${id}?orgId=${orgId}`)?.pipe(catchError(handleError))
  }

  getPaymentPlansDates(payload: { date: string }): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_PAYMENT.PAYMENT_PLAN_DATES}`, payload)?.pipe(catchError(handleError))
  }

  checkSKBucksDiscount(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_DISCOUNT.CHECK_SKBUCKS_DISCOUNT}`, payload)?.pipe(catchError(handleError))
  }
}
