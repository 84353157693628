// confirmation.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  private confirmationSubject = new Subject<any>();

  getConfirmation() {
    return this.confirmationSubject.asObservable();
  }

  confirm(title: string, message: string, onConfirm: () => void, onCancel: () => void, confirmButtonText: string = 'Yes', cancelButtonText: string = 'Cancel') {
    this.confirmationSubject.next({ title, message, onConfirm, onCancel, confirmButtonText, cancelButtonText });
  }

  close() {
    this.confirmationSubject.next(null);
  }
}
