import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SuccessErrorService } from './success-error-popup.service';

@Component({
  selector: 'app-success-error-popup',
  templateUrl: './success-error-popup.component.html',
  styleUrls: ['./success-error-popup.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
})

export class SuccessErrorPopupComponent implements OnInit, OnDestroy {
  successError: any;
  private _subscription !: Subscription;

  constructor(private SuccessErrorService: SuccessErrorService) { }

  ngOnInit() {
    this._subscription = this.SuccessErrorService.getSuccessError().subscribe((successError: any) => {
      this.successError = successError;
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  successErrorConfirm() {
    if (this.successError && this.successError.onConfirm) {
      this.successError.onConfirm();
    }
    this.close();
  }

  successErrorCancel() {
    if (this.successError && this.successError.onCancel) {
      this.successError.onCancel();
    }
    this.close();
  }

  closePopup() {
    this.successErrorConfirm();
  }

  close() {
    this.SuccessErrorService.close();
  }
}
