<div
  *ngIf="successError"
  class="alert-popup"
  tabindex="0"
  role="button"
  (keydown.enter)="closePopup()"
  (click)="closePopup()">
  <div class="relative min-w-80 max-w-80" >
    <div class="alert-content text-center">

      <div style="text-align: center; ">
        <div style="margin-top : 40px; color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;"> {{ successError.title }}</div>
        <div style="display: flex; justify-content: center; margin: 20px 0px 20px 0px">
          <img style="height: 74px; width: 74px;" [src]="successError.imageUrl" alt="success"/>
        </div>
        <div><p style="font-size : 14px; color: #3B3B3B; font-family: 'Arial', sans-serif;"> {{ successError.message }} </p> </div>
      </div>

    </div>
  </div>
</div>
