import moment from "moment";

const WEEKDAYS: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const SMSTermsConditions = 'https://sketchplay.com/terms-and-conditions.php#section5';
const currentYear = moment().format('YYYY');
enum IPaymentPlanPaymentStatus {
  PLAN_COMPLETED = 'PLAN COMPLETED',
  ACTIVE_PLAN = 'ACTIVE PLAN',
  PLAN_CANCELED = 'PLAN CANCELED',
  INSTALLMENT_FAILED = 'INSTALLMENT FAILED'
}
enum EventType {
  CAMP = 'camp',
  CLINIC = 'clinic',
  DAILY_TRAINING = 'dailytraining',
}

const quillConfig = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    // ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    // [{ color: [] }, { background: [] }],
    ['link']
  ],
  syntax: true,
};

const paymentPlanTerms: string = 'https://firebasestorage.googleapis.com/v0/b/sketchplay-be1a2.appspot.com/o/Sketchplay-PaymentPlans-Terms-and-Conditions.pdf?alt=media&token=2dae1497-0350-4977-8997-e7b1db02a762';

enum EventPaymentPlanStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  PAID = 'PAID',
  EXPIRED = 'EXPIRED',
  SUCCESSFUL = 'SUCCESSFUL'
}

enum ECellType {
  ATHLETE_NAME = "ATHLETE_NAME",
  EVENT_NAME = "EVENT_NAME",
  PLAN_NAME = "PLAN_NAME",
  PACKAGE_NAME = "PACKAGE_NAME"
}

enum EPURCHASE_TYPE {
  PACKAGE = 'package',
  EVENT = 'event',
  PAYMENT_PLAN = 'payment_plan',
  SUBSCRIPTION = 'subscription',
  PRODUCT_PAYMENT_PLAN = 'product_payment_plan'
}

enum PaymentPlanTransactionType {
  PaymentLink = "PaymentLink",
}

enum EventRegisterPaymentType {
  CASH_PAYMENT = 'CASH_PAYMENT',
  INVOICE_URL_PAYMENT = 'INVOICE_URL_PAYMENT'
}

enum EventInvoiceLinkPaymentStatus {
  INVOICE_SENT = 'INVOICE SENT',
  PAID = 'PAID'
}

enum GeneratedLinkType {
  PAYMENT_PLAN_PAYOFF_LINK = 'PAYMENT_PLAN_PAYOFF_LINK',
  REGISTER_EVENT_INVOICE_LINK = 'REGISTER_EVENT_INVOICE_LINK',
}

export {
  currentYear, ECellType, EPURCHASE_TYPE, EventInvoiceLinkPaymentStatus, EventPaymentPlanStatus, EventRegisterPaymentType, EventType, GeneratedLinkType, IPaymentPlanPaymentStatus, paymentPlanTerms, PaymentPlanTransactionType, quillConfig, SMSTermsConditions,
  WEEKDAYS
};

