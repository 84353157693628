import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { catchError, Observable } from 'rxjs';
import { API_ADMIN_CENTER, API_DISCOUNT, API_QR_CODE } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AdminCenterService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }
  // get user list
  getAllUserList(payload: any): Observable<any> {
    const params = new HttpParams()
      .set('isActive', payload.isActive)
      .set('orgId', payload.orgId)
      .set('startDate', payload.startDate)
      .set('search', payload.search)
      .set('role', payload.role)
      .set('limit', 50)
      .set('skip', 0)

    const options = { params: params };
    return this.http.get(environment.API + API_ADMIN_CENTER.GET_USER_LIST, options)?.pipe(catchError(handleError));
  }

  getAllRolesForUserListPage(): Observable<any> {
    return this.http.get(environment.API + API_ADMIN_CENTER.GET_ALL_ROLES)?.pipe(catchError(handleError));
  }

  getOnlyUserRoles(): Observable<any> {
    return this.http.get(environment.API + API_ADMIN_CENTER.GET_ROLES)?.pipe(catchError(handleError));
  }

  addEventSprint(payload: object): Observable<any> {
    return this.http.post(environment.API + API_ADMIN_CENTER.EVENT_SPRINT, payload)?.pipe(catchError(handleError));
  }

  updateEventSprint(payload: object): Observable<any> {
    return this.http.put(environment.API + API_ADMIN_CENTER.EVENT_SPRINT, payload)?.pipe(catchError(handleError));
  }

  deleteEventSprint(id: number | string): Observable<any> {
    return this.http.delete(environment.API + API_ADMIN_CENTER.DELETE_EVENT_SPRINT + id)?.pipe(catchError(handleError));
  }

  deleteDiscount(id: number | string): Observable<any> {
    return this.http.delete(environment.API + API_DISCOUNT.DELETE_DISCOUNT + id)?.pipe(catchError(handleError));
  }

  addEventDiscount(payload: object): Observable<any> {
    return this.http.post(environment.API + API_DISCOUNT.DISCOUNT, payload)?.pipe(catchError(handleError));
  }
  activeDeactiveDiscountCode(scoreId: any, payload: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}${API_DISCOUNT.DISCOUNT}/${scoreId}`, payload)?.pipe(catchError(handleError));
  }

  getQrCode(orgId: number | null): Observable<any> {
    return this.http.get(`${environment.API}${API_QR_CODE.GET_QR_CODE_URL_BY_ORG}/${orgId}`)?.pipe(catchError(handleError));
  }
}
