import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from '@env/environment';
import { format } from 'date-fns';
import moment from "moment";
import { throwError } from 'rxjs';
import Swal from "sweetalert2";

let now = moment();
let CURRENT_TIME = now.format('hh:mm A');
let CURRENT_DATE = now.format("YYYY-MM-DD");
const EMAIL_REG = /^\s*[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})\s*$/;
const PHONE_REG = /^\(\d{3}\)\s\d{3}-\d{4}$/;

function crrDateTimeRecurr() {
  let now = moment();
  CURRENT_TIME = now.format('hh:mm A');
  CURRENT_DATE = now.format("YYYY-MM-DD");
}

export enum DeviceType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}
export enum templateId {
  imagesTemplateId = 'd-f4d186efb1054161adc6a0974386f184',
}
setInterval(crrDateTimeRecurr, 10000);

function checkIfUserIsAdminOrParent(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles?.some((role: any) => role.type === 'admin' || role.type === 'parent');
}

function checkIfUserIsAdminOrParentorAthlete(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles?.some((role: any) => role.type === 'admin' || role.type === 'parent' || role.type === 'athlete');
}

function checkFirstOrgID(userDetails: any): boolean {
  return userDetails?.organizations[0]?.id;
}

//check if the role is admin for current org
function checkIfUserIsAdmin(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles.some((role: any) => role.type === 'admin');
}

function checkIfUserIsParentInOrg(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles.some((role: any) => role.type === 'parent');
}

function checkIfUserIsAthleteInOrg(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles.some((role: any) => role.type === 'athlete');
}

function checkIfUserIsOnlyAthlete(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles.length === 1 && filteredRoles[0].type === 'athlete';
}

function checkIfUserIsParent(userDetails: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  const hasParentRole = organization?.some((org: any) => org.roles.some((role: any) => role.type === 'parent'));
  return hasParentRole;
}

function checkIfUserIsAthlete(userDetails: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  const hasAthleteRole = organization?.some((org: any) => org.roles.some((role: any) => role.type === 'athlete'));
  return hasAthleteRole;
}


function checkIfUserIsAdminInAllOrg(userDetails: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  const hasAdminRole = organization?.some((org: any) => org.roles.some((role: any) => role.type === 'admin'));
  return hasAdminRole;
}

function checkIfUserIsCoach(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles.some((role: any) => role.type === 'coach');
}

function checkIfUserIsManager(userDetails: any, orgId: any): boolean {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles.some((role: any) => role.type === 'manager');
}

function checkIfUserIsAdminOrCoachorManager(): boolean {
  const currentOrgId = getSaveData("activeLinkforOrganization");
  const userDetailsFromUserMe = getSaveData('userDetailsFromUserMe');
  let organization = parentChildOrganization(userDetailsFromUserMe?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == currentOrgId);
  const filteredRoles = organization ? organization.roles : [];
  return filteredRoles?.some((role: any) => role.type === 'admin' || role.type === 'coach' || role.type === 'manager');
}

function getSaveData(KEY: string): any {
  const data = localStorage.getItem(KEY);
  if (data) {
    return JSON.parse(data);
  }
  return null;
}


const getFileName = (url: string): string => {
  const match = url?.match(/\/([^\/?#]+)[^\/]*$/);
  return match ? match[1] : "";
}

// Create a file object with the image URL
const getImgFileFromFileURL = (imageURL: string, fileName: string): File | null => {
  if (imageURL && fileName) {
    return new File([imageURL], fileName, { type: "image/png" });
  } else {
    return null;
  }
};

const showAlertMessage = (text: string, type: 'success' | 'error', onCloseCallback?: () => void, titleText?: string) => {
  const imageUrl = type === 'success' ? 'assets/images/success@2x.png' : 'assets/images/info.png';
  const textBelowIcon = type === 'success' ? 'Tap anywhere to continue.' : 'Tap anywhere to try again.';
  Swal.fire({
    title: '',
    html: `
      <div style="text-align: center; ">
        <div style="margin-top : 40px; color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;"> ${text}</div>
        <div style="display: flex; justify-content: center; margin: 20px 0px 20px 0px"><img style="height: 74px; width: 74px;" src="${imageUrl}" alt="success"/></div>
        <div style=""><p style="font-size : 14px; color: #3B3B3B; font-family: 'Arial', sans-serif;"> ${textBelowIcon} </p> </div>
      </div>
    `,
    icon: undefined,
    showConfirmButton: false,
    animation: false,
    buttonsStyling: false,
    showCloseButton: false,
    allowOutsideClick: true,
    customClass: {
      popup: 'custom-popup-class',
      container: 'custom-container-class'
    },
    didOpen: (popup) => {
      popup.addEventListener('click', () => {
        Swal.clickConfirm();
      });
    },
    didClose: onCloseCallback
  });
}
export const showAlertMessageForCustomButton = (
  text: string,
  type: 'success' | 'error',
  onCloseCallback?: () => void,
  titleText?: string,
  buttonText?: string,
  buttonCallback?: () => void
) => {
  const imageUrl = type === 'success' ? 'assets/images/success@2x.png' : 'assets/images/info.png';
  const textBelowIcon = type === 'success' ? 'Tap anywhere to continue.' : 'Tap anywhere to try again.';

  Swal.fire({
    title: '',
    html: `
      <div style="text-align: center;">
        <div style="margin-top: 40px; color: #3B3B3B; font-weight: 800; font-size: 20px; font-family: 'Poppins', sans-serif;">${text}</div>
        <div style="display: flex; justify-content: center; margin: 20px 0px 20px 0px">
          <img style="height: 74px; width: 74px;" src="${imageUrl}" alt="success"/>
        </div>
        <div><p style="font-size: 14px; color: #3B3B3B; font-family: 'Arial', sans-serif;">${textBelowIcon}</p></div>
        <button id="custom-button" style="margin-top: 40px; padding: 8px 15px; background: #007bff; color: white; border: none; border-radius: 5px; font-size: 13px; cursor: pointer;">
          ${buttonText || 'OK'}
        </button>
      </div>
    `,
    icon: undefined,
    showConfirmButton: false,
    animation: false,
    buttonsStyling: false,
    showCloseButton: false,
    allowOutsideClick: true,
    customClass: {
      popup: 'custom-button-popup-class',
      container: 'custom-container-class'
    },
    didOpen: (popup) => {
      // Stop propagation inside the popup (so it doesn't close on button clicks)
      popup.addEventListener('click', () => {
        Swal.clickConfirm();
      });

      // Handle custom button click
      const customButton = document.getElementById('custom-button');
      if (customButton) {
        customButton.addEventListener('click', () => {
          Swal.clickConfirm(); // Prevent closing when clicking the button
          if (buttonCallback) {
            buttonCallback(); // Execute custom function when clicked
          }
          Swal.close(); // Close the alert only when the button is clicked
        });
      }
    },
    // didClose: onCloseCallback
  });
};
export enum SUBSCRIPTION_CADENCE {
  MONTHLY = 'month',
  YEARLY = 'year',
}
const formatTime = (value: string): string => {
  if (!value) return '';
  // Parse the input string to get the hours and minutes
  const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
  // Create a Date object to leverage date-fns
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  // Format time using date-fns
  return format(date, 'hh:mm a');
}

const formatDate = (value: Date | string): string => {
  if (!value) return '';
  // Parse the input value to ensure it's a Date object
  const date = typeof value === 'string' ? new Date(value) : value;
  return format(date, 'MMMM dd, yyyy');
}

function formatPhoneNumber(phoneNumber: any) {
  return phoneNumber.replace(/\D/g, '');
}

function removeDuplicates(array: any[], key: string) {
  return array.filter((item, index) => {
    // Return the first occurrence of each object based on the specified key
    return index === array.findIndex(obj => obj[key] === item[key]);
  });
}

function timeToMinutes(timeString: string) {
  const [time, period] = timeString.split(' ');
  const [hours, minutes] = time.split(':').map(Number);
  let totalMinutes = hours * 60 + minutes;
  if (period === 'PM' && hours !== 12) {
    totalMinutes += 12 * 60; // Convert PM to 24-hour format
  } else if (period === 'AM' && hours === 12) {
    totalMinutes -= 12 * 60; // Convert 12 AM to 0
  }
  return totalMinutes;
}

function compareTimes(start: string, end: string) {
  const startMinutes = timeToMinutes(start);
  const endMinutes = timeToMinutes(end);

  if (startMinutes < endMinutes) {
    return true;
  } else {
    return false;
  }
}

function formatPhoneNumberPattern(number: any) {
  // Remove all non-digit characters from the input string
  const cleaned = number.replace(/\D/g, '');

  // Apply the desired phone number format
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // Return the original value if it doesn't match the expected format
  return number;
}

function formatCurrency(amount: number = 0, currency?: string) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ? currency : "USD",
  });
  return formatter.format(amount);
};

function charValidate(text: string, limit: number): string {
  if (text !== undefined && limit !== undefined) {
    if (text !== null) {
      if (text.length > limit) {
        return `${text.substring(0, limit - 3)}...`;
      } else {
        return `${text.substring(0, limit)}`;
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

function parseDateWithoutTimezone(date: string | Date, format?: string) {
  if (!date) {
    return ''; // Return an empty string if the date is not provided
  }
  if (typeof date === 'string') {
    return moment(date, moment.ISO_8601, true).isValid() ? moment(date).format(format ? format : "YYYY-MM-DD") : moment(date, 'MM/DD/YYYY').format(format ? format : "YYYY-MM-DD");
  } else {
    return moment(date).format(format ? format : "YYYY-MM-DD");
  }
};

function dobTimezoneFormat(date: string | Date, format?: string) {
  if (typeof date === 'string') {
    return moment.utc(date).format(format ? format : "YYYY-MM-DD");
  } else {
    return moment(date).format(format ? format : "YYYY-MM-DD");
  }
};

function formatMaskPhoneNumber(number: number | string): string {
  // Convert the number to a string
  let numStr = '';
  if (typeof number === 'number') {
    numStr = number.toString();
  } else {
    numStr = number;
  }
  // Extract the parts of the number
  let areaCode = numStr.substring(0, 3);
  let centralOfficeCode = numStr.substring(3, 6);
  let lineNumber = numStr.substring(6, 10);
  // Format the parts into the desired format
  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
}

function downloadFile(url: string) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = 'teamRoster.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
function noLeadingOrTrailingSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  };
}

function notOnlyWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { 'notOnlyWhitespace': true } : null;
  };
}

function extractFileParts(filename: string): string {
  if (filename) {
    // Extract the first 5 characters
    const firstPart = filename.slice(0, 5);
    // Find the position of the last dot
    const dotIndex = filename.lastIndexOf('.');
    // Extract the last 5 characters before the dot
    const lastPart = filename.slice(dotIndex - 5, dotIndex);
    // Extract all characters after the dot
    const extension = filename.slice(dotIndex);
    // Combine the parts
    return `${firstPart}.....${lastPart}${extension}`;
  } else {
    return '';
  }
}

function parentChildOrganization(orgClients: any) {
  let result: any = [];
  orgClients?.forEach((client: any) => {
    // Add the parent organization
    result.push({
      id: client.id,
      name: client.name,
      isSubOrganization: false,
      isParent: true,
      roles: client.roles
    });

    // Add the sub-organizations
    client.subOrganizations?.forEach((subOrg: any) => {
      result.push({
        id: subOrg.id,
        name: subOrg.name, // Add indentation
        isSubOrganization: true,
        parentOrgId: client.id,
        isParent: false,
        roles: subOrg.roles
      });
    });
  });
  return result;
}

function parentChildOrganizationLists(orgClients: any) {
  let result: any = [];
  orgClients.forEach((client: any) => {
    // Add the parent organization
    result = [
      ...result,
      {
        id: client.id,
        name: client.name,
        isSubOrganization: false,
        isParent: true,
        roles: client?.roles
      }
    ];
    // Add the sub-organizations
    client.subOrganizations.forEach((subOrg: any) => {
      result = [
        ...result,
        {
          id: subOrg.id,
          name: subOrg.name,
          isSubOrganization: true,
          parentOrgId: client.id,
          isParent: false,
          roles: subOrg?.roles
        }
      ];
    });
  });
  return result;
}

function setVideoThumbnailImage(url: string): string {
  if (url) {
    return url;
  } else {
    return 'assets/images/video-poster-img.jpg';
  }
}

function checkIfUserIsAdminWithOrgDetail(userDetails: any, orgId: any) {
  let organization = parentChildOrganization(userDetails?.organizations ?? []) ?? [];
  organization = organization?.find((org: any) => org.id == orgId);
  const filteredRoles = organization ? organization.roles : [];
  if (filteredRoles.some((role: any) => role.type === 'admin')) {
    return organization;
  } else {
    return null
  }
}

function checkDeviceBreakPoint() {
  let isMobile = false;
  let isTab = false;
  let isDesktop = false;
  let smallerDevice = false;
  if (window.matchMedia('(max-width: 640px)').matches) {
    isMobile = true;
  }
  if (window.matchMedia('(max-width: 380px)').matches) {
    smallerDevice = true;
  }
  if (window.matchMedia('(max-width: 1024px)').matches) {
    isTab = true;
  } else {
    isDesktop = true;
  }
  return { isMobile, isTab, isDesktop, smallerDevice }
}

function checkOrganizationIsExist(orgId: number) {
  const userDetail = localStorage.getItem('userDetailsFromUserMe');
  if (userDetail) {
    const jsonData = JSON.parse(userDetail);
    const allOrg = parentChildOrganization(jsonData?.organizations)
    const getOrg = allOrg?.find((x: any) => x.id === orgId);
    if (getOrg) {
      return true
    }
  }
  return false
}

function addDateSuffix(dateNum: number) {
  const lastDigit = dateNum % 10;
  const lastTwoDigits = dateNum % 100;

  // Handle special cases for numbers ending in 11, 12, and 13
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return dateNum + 'th';
  }

  switch (lastDigit) {
    case 1:
      return dateNum + 'st';
    case 2:
      return dateNum + 'nd';
    case 3:
      return dateNum + 'rd';
    default:
      return dateNum + 'th';
  }
}

function convertSplitDOBFormat(dateStr: string): string | null {
  if (dateStr) {
    let dobDate = '';
    let [month, day, year] = dateStr?.includes('-')
      ? dateStr.split('-')
      : dateStr.includes('/')
        ? dateStr.split('/')
        : [];

    if (month && day && year) {
      dobDate = `${year}-${month}-${day}`;
    }
    return dobDate;
  }
  return null;
}
function trimSpacesFromCenter(str: string) {
  return str?.replace(/\s+/g, ' ')?.trim();
}

function setExtraFieldsPayloadData(eventData: any) {
  const reqPayload = {
    "parent": {
      "firstName": trimSpacesFromCenter(eventData?.parentInfo?.firstName),
      "lastName": trimSpacesFromCenter(eventData?.parentInfo?.lastName),
      "phone": eventData?.parentInfo?.phone ? formatPhoneNumber(eventData?.parentInfo?.phone) : '',
      "email": trimSpacesFromCenter(eventData?.parentInfo?.email)
    },
    "parent2": (eventData?.organization?.id === environment.pvvaOrgId || eventData?.parentOrg === environment.pvvaOrgId) ? {
      "firstName": trimSpacesFromCenter(eventData?.parent2Info?.firstName),
      "lastName": trimSpacesFromCenter(eventData?.parent2Info?.lastName),
      "phone": eventData?.parent2Info?.phone ? formatPhoneNumber(eventData?.parent2Info?.phone) : '',
      "email": trimSpacesFromCenter(eventData?.parent2Info?.email)
    } : null,
    "athlete": {
      "grade": eventData?.athleteMoreInfo?.grade,
      "graduationYear": eventData?.athleteMoreInfo?.graduationYear || '',
      "schoolName": eventData?.athleteMoreInfo?.schoolName || '',
      "position": trimSpacesFromCenter(eventData?.athleteMoreInfo?.position) || '',
      "clubTeam": eventData?.athleteMoreInfo?.clubTeam || '',
      "clubCoachName": trimSpacesFromCenter(eventData?.athleteMoreInfo?.clubCoachName) || '',
      "clubCoachEmail": trimSpacesFromCenter(eventData?.athleteMoreInfo?.clubCoachEmail) || '',
      "hsTeam": eventData?.athleteMoreInfo?.hsTeam || '',
      "hsCoachName": trimSpacesFromCenter(eventData?.athleteMoreInfo?.hsCoachName) || '',
      "hsCoachEmail": trimSpacesFromCenter(eventData?.athleteMoreInfo?.hsCoachEmail) || '',
      "address1": eventData?.athleteMoreInfo?.address || '',
      "address2": eventData?.athleteMoreInfo?.address2 || '',
      "city": eventData?.athleteMoreInfo?.city || '',
      "state": eventData?.athleteMoreInfo?.state || '',
      "shirtSize": trimSpacesFromCenter(eventData?.athleteMoreInfo?.shirtSize ?? '') || '',
      "shortSize": trimSpacesFromCenter(eventData?.athleteMoreInfo?.shortSize ?? '') || '',
      "emergencyContactName": trimSpacesFromCenter(eventData?.athleteMoreInfo?.emergencyContactName) || '',
      "emergencyContactPhone": eventData?.athleteMoreInfo?.emergencyContactPhone ? formatPhoneNumber(eventData?.athleteMoreInfo?.emergencyContactPhone) : '',
      "haveMedicalIssue": eventData?.athleteMoreInfo?.haveMedicalIssue || false,
      "medicalIssue": eventData?.athleteMoreInfo?.medicalIssue || '',
      "experience": eventData?.athleteMoreInfo?.experience,
      "interested": eventData?.athleteMoreInfo?.interested === 1 ? true : false
    },
    "emergencyContact": (eventData?.organization?.id === environment.pvvaOrgId || eventData?.parentOrg === environment.pvvaOrgId) ? {
      "firstName": trimSpacesFromCenter(eventData?.emergencyContactInfo?.firstName),
      "lastName": trimSpacesFromCenter(eventData?.emergencyContactInfo?.lastName),
      "phone": formatPhoneNumber(eventData?.emergencyContactInfo?.phone),
      "email": trimSpacesFromCenter(eventData?.emergencyContactInfo?.email)
    } : null,
    "usaLacrosseNumber": eventData?.usaLacrosseNumber ? eventData?.usaLacrosseNumber : null,
    "gridironPosition": eventData?.gridironPosition ? eventData?.gridironPosition : null
  }
  if ((eventData?.organization?.id === environment.calusaOrgId ||
    eventData?.parentOrg === environment.calusaOrgId ||
    environment.lacOrgs.includes(eventData?.parentOrg) ||
    environment.lacOrgs.includes(eventData?.organization?.id)) &&
    eventData?.organization?.id != environment.pvvaOrgId &&
    eventData?.parentOrg != environment.pvvaOrgId &&
    eventData?.organization?.id != environment.orgId &&
    eventData?.parentOrg != environment.orgId
  ) {
    return {
      "usaLacrosseNumber": eventData?.usaLacrosseNumber,
      "athlete": {
        "position": trimSpacesFromCenter(eventData?.athleteMoreInfo?.position) || '',
      }
    };
  } if (eventData?.organization?.id === environment.gridirongangOrgId || eventData?.parentOrg === environment.gridirongangOrgId) {
    return { "gridironPosition": eventData?.gridironPosition };
  } else {
    return reqPayload;
  }
  // commenting for future code change
  // return (eventData?.organization?.id === environment.calusaOrgId || eventData?.parentOrg === environment.calusaOrgId) ? { "usaLacrosseNumber": eventData?.usaLacrosseNumber } : reqPayload;
}

function focusFirstInvalidInput(formGroup: FormGroup, controlNames: string[]): void {
  for (const controlName of controlNames) {
    const control = formGroup.get(controlName);
    if (control && control.invalid) {
      setTimeout(() => {
        const element = document.getElementById(controlName);
        if (element) {
          element.focus();
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
      return;
    }
  }
}

function formatToFloat(value: any) {
  // Convert the input to a number
  const num = parseFloat(value);

  // Ensure the value is a valid number
  if (isNaN(num)) {
    throw new Error("Invalid input: not a number");
  }

  // Check if it already has decimals
  if (!value.toString().includes('.')) {
    return parseFloat(num.toFixed(2));
  }

  return num; // Return as is if it already has decimals
}

function formatStringAmountToDecimal(value: number): string {
  // Check if the number is an integer
  if (Number.isInteger(value)) {
    // Return the value with ".00"
    return `${value}.00`;
  } else {
    // Return the value as a string with no modification
    return value.toFixed(2);
  }
}

function handleError(error: any): any {
  if (error?.status === 401 || error === 'Expired Token' || error === 'Invalid token') {
    return {};
  }

  if (error.error instanceof ErrorEvent) {
    showAlertMessage(error.error.message ?? "Something went wrong!", "error");
  } else {
    showAlertMessage(error?.error?.message ?? error ?? "Something went wrong!", "error");
  }
  return throwError(() => error?.error?.message ?? error ?? 'Something bad happened; please try again later.');
}

function checkZeroOnly(control: AbstractControl): ValidationErrors | null {
  const regex = /^0(\.0{1,2})?$/;
  return regex.test(control.value) ? { zeroNotAllowed: true } : null;
}

function hyphenOnlyValidator(control: AbstractControl): ValidationErrors | null {
  const regex = /^-$|^NaN$/;
  return regex.test(control.value) ? { invalidValue: true } : null;
}

function amountValidator(balance: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const enteredValue = parseFloat(control.value);

    if (isNaN(enteredValue)) {
      return { invalidNumber: true }; // Invalid number check
    }

    if (enteredValue < -balance) {
      return { exceedsNegativeBalance: true }; // Amount is below -balance
    }

    // commenting for future code change
    // if (enteredValue > balance) {
    //   return { exceedsPositiveBalance: true }; // Amount is greater than balance
    // }

    return null; // Valid input
  };
}

export {
  addDateSuffix, amountValidator, charValidate, checkDeviceBreakPoint, checkFirstOrgID, checkIfUserIsAdmin, checkIfUserIsAdminInAllOrg, checkIfUserIsAdminOrCoachorManager, checkIfUserIsAdminOrParent, checkIfUserIsAdminOrParentorAthlete, checkIfUserIsAdminWithOrgDetail, checkIfUserIsAthlete, checkIfUserIsAthleteInOrg, checkIfUserIsCoach,
  checkIfUserIsManager, checkIfUserIsOnlyAthlete, checkIfUserIsParent, checkIfUserIsParentInOrg, checkOrganizationIsExist, checkZeroOnly, compareTimes, convertSplitDOBFormat, CURRENT_DATE, CURRENT_TIME, dobTimezoneFormat, downloadFile, EMAIL_REG, extractFileParts, focusFirstInvalidInput, formatCurrency, formatDate, formatMaskPhoneNumber, formatPhoneNumber,
  formatPhoneNumberPattern, formatStringAmountToDecimal, formatTime, formatToFloat, getFileName, getImgFileFromFileURL, getSaveData, handleError, hyphenOnlyValidator, noLeadingOrTrailingSpacesValidator, notOnlyWhitespaceValidator, parentChildOrganization, parentChildOrganizationLists, parseDateWithoutTimezone, PHONE_REG, removeDuplicates, setExtraFieldsPayloadData, setVideoThumbnailImage, showAlertMessage, trimSpacesFromCenter
};

