import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable, catchError } from "rxjs";
import { API_ADMIN_CENTER, API_FEATURE, API_ORGANIZATION } from "../helpers/api_constant";
import { handleError } from "../helpers/utils";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
  providedIn: 'root',
})
export class SidebarService {

  private menuItemsSubject = new BehaviorSubject<any[]>([]);
  menuItems$ = this.menuItemsSubject.asObservable();

  private featureItemsSubject = new BehaviorSubject<any[]>([]);
  featureItems$ = this.featureItemsSubject.asObservable();

  private activeFeatureSubject = new BehaviorSubject<any>(null);
  activeFeatures$ = this.activeFeatureSubject.asObservable();

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  featureList(orgId: any): Observable<any> {
    return this.http.get(environment.API + `${API_FEATURE.GET_FEATURE_LIST}?orgId=` + orgId)?.pipe(catchError(handleError));
  }

  communityList(skip: any, take: any, search: any): Observable<any> {
    return this.http.get(environment.API + `${API_ORGANIZATION.GET_PUBLIC_ORGANIZATION}?skip=` + 0 + '&limit=' + 100 + '&search=' + (search ? search : ''))?.pipe(catchError(handleError));
  }

  checkAccessCode(accessCode: string | number): Observable<any> {
    return this.http.get(environment.API + `${API_ORGANIZATION.CHECK_ACCESS_CODE}/` + accessCode)?.pipe(catchError(handleError));
  }

  getAllRoles(): Observable<any> {
    return this.http.get(environment.API + API_ADMIN_CENTER.GET_ROLES)?.pipe(catchError(handleError));
  }
  getAllRolesWithNoAuth(): Observable<any> {
    return this.http.get(environment.API + API_ADMIN_CENTER.GET_ROLES_WITHOUT_AUTH)?.pipe(catchError(handleError));
  }
  getAllRolesWithAdmin(): Observable<any> {
    return this.http.get(environment.API + API_ADMIN_CENTER.GET_ALL_ROLES)?.pipe(catchError(handleError));
  }

  getAllGrades(): Observable<any> {
    return this.http.get(environment.API + API_FEATURE.GET_GRADES)?.pipe(catchError(handleError));
  }

  joinPrivateOrganization(res: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_ORGANIZATION.JOIN_ORGANIZATION}`, res)?.pipe(catchError(handleError));
  }

  viewMoreOrganizationOnTop(res: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}${API_ORGANIZATION.ORGANIZATION_ON_TOP}/${res?.id}`, '')?.pipe(catchError(handleError));
  }

  getAllStates(): Observable<any> {
    return this.http.get(environment.API + API_FEATURE.GET_ALL_STATES)?.pipe(catchError(handleError));
  }

  getAllCities(stateCode: string): Observable<any> {
    return this.http.get(environment.API + `${API_FEATURE.GET_ALL_CITIES}/${stateCode}`)?.pipe(catchError(handleError));
  }

  addChildCommunity(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_ORGANIZATION.SUB_ORGANIZATION}`, payload)?.pipe(catchError(handleError));
  }

  updateCommunity(subOrgId: number, payload: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}${API_ORGANIZATION.SUB_ORGANIZATION}/${subOrgId}`, payload)?.pipe(catchError(handleError))
  }

  updateMenuItems(menuItems: any[]) {
    this.menuItemsSubject.next(menuItems);
  }

  updateFeatureItems(featureItems: any[]) {
    this.featureItemsSubject.next(featureItems);
  }

  activateDeactivateClient(payload: any): Observable<any> {
    // payload["themeType"] = "theme1";
    return this.http.put<any>(`${environment.API}${API_ORGANIZATION.ORG_ACTIVE_DEACTIVE}`, payload)?.pipe(catchError(handleError))
  }

  getActiveFeatures(orgId: any): Observable<any> {
    return this.http.get(environment.API + `${API_FEATURE.GET_ACTIVE_FEATURE}?orgId=` + orgId)?.pipe(catchError(handleError));
  }

  updateActiveFeatures(data: any) {
    this.activeFeatureSubject.next(data);
  }

}
