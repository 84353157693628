<div *ngIf="confirmation" class="alert-popup">
  <div class="relative min-w-80 max-w-80">
    <div class="alert-content text-center">
      <h2 class="mb-10 text-[20px] font-bold"> {{ confirmation.title }}</h2>
      <button
          (click)="confirm()"
          class="title-btn w-40 text-white btn bg-custom-500 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
          {{ confirmation.confirmButtonText }}
        </button>
      <p class="mb-10 mt-10">{{ confirmation.message }}</p>
      <span
          class="text-red-500 font-medium cursor-pointer"
          (click)="cancel()"
          ><i class="ri-close-large-line fill:text-red-500"></i> {{ confirmation.cancelButtonText }}</span
        >
    </div>
  </div>
</div>
