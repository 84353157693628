<div class="max-w-[410px] m-auto">
  <div
    class="col-span-12 border-b dark:border-[#1D334E] border-[#e2e8f0] mb-4 pb-3">
    <h4 class="dark:text-white mb-3 text-center">Registered Users</h4>
    <p class="dark:text-[#C9D7E9]">
      Registered users for this event are listed below. You may edit their
      registration by checking them in or unregistering the user as needed.
    </p>
  </div>
  <div class="col-span-12" *ngIf="sprintDates?.length > 0">
    <div class="border-b dark:border-[#1D334E] border-[#e2e8f0] mb-4 pb-4">
      <label class="ml-2">Filter by Event Date</label>
      <div
        class="items-center border border-[#92AFD326] dark:bg-[#1A2F48] rounded-md p-[2px] mt-2">
        <div class="selectDropdown bg-transparent outline-none">
          <ng-select
            [items]="sprintDates"
            id="sprintDate"
            name="sprintDate"
            bindValue="id"
            bindLabel="labelledDate"
            class="custom-ng-select w-full"
            [(ngModel)]="selectedSprintDate"
            [clearable]="false"
            (change)="onChangeDate($event)"
            placeholder="Select date to view Register user"
            aria-placeholder="Select date to view Register user">
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.labelledDate }}
            </ng-template>
          </ng-select>
        </div>
        <!-- <input type="text" placeholder="Choose Athlete" class="bg-transparent outline-none"> -->
      </div>
      <!-- <div
      *ngIf="sprintDate.invalid && (sprintDate.dirty || isFormSubmitted)"
      class="invalid-feedback">
      <div
          *ngIf="sprintDate.errors?.['required']"
          class="text-red-500 ml-1 mt-1">
          This field is required.
      </div>
    </div> -->
      <!-- <input
        type="text"
        placeholder="Select date to Register"
        class="bg-transparent outline-none" />
      <i
        class="ri-arrow-down-circle-line absolute right-2 flex top-0 h-full items-center text-xl"></i> -->
    </div>
  </div>
</div>

<div class="flex flex-col items-center mt-4">
  <div
    class="dark:bg-[#122337] bg-[#f3f6f9] w-fit rounded-md mb-5 max-w-[435px] w-full"
    *ngFor="let item of userData">
    <div class="flex items-center gap-3 relative w-full">
      <div
        class="flex items-center gap-3 px-6 py-8 md:py-6 lg:py-6 xl:py-6 2xl:py-6 pr-[80px] w-full md:min-w-[350px]">
        <div
          class="rounded-full bg-slate-100 shrink-0 dark:bg-zink-600 relative">
          <!-- <div *ngIf="!isAdminorParent" class="relative" [ngClass]=" item?.user?.userStatus !== 'CHECKEDIN' ? (userDetail.id == item?.user?.id) ? 'cursor-pointer' : '' : '' " (click)=" item?.user?.userStatus !== 'CHECKEDIN' ? ((userDetail.id == item?.user?.id) ? checkIn(item?.user?.id,item?.user?.userStatus) : '') : '' "> -->
          <div
            class="relative"
            [ngClass]="
              isAdminorParent
                ? item?.user?.userStatus !== 'CHECKEDIN' && eventType !== 'past'
                  ? 'cursor-pointer'
                  : ''
                : userDetail.id == item?.user?.id
                  ? item?.user?.userStatus !== 'CHECKEDIN' &&
                    eventType !== 'past'
                    ? 'cursor-pointer'
                    : ''
                  : ''
            "
            (click)="
              isAdminorParent
                ? item?.user?.userStatus !== 'CHECKEDIN'
                  ? item?.isAllowedForRegister &&
                    item?.isAllowed &&
                    eventType !== 'past'
                    ? checkIn(item?.user?.id, item?.user?.userStatus)
                    : ''
                  : ''
                : userDetail.id == item?.user?.id
                  ? item?.user?.userStatus !== 'CHECKEDIN'
                    ? item?.isAllowedForRegister &&
                      item?.isAllowed &&
                      eventType !== 'past'
                      ? checkIn(item?.user?.id, item?.user?.userStatus)
                      : ''
                    : ''
                  : ''
            ">
            <img
              *ngIf="item?.user?.profilePictureUrl"
              alt=""
              class="rounded-full size-[60px] md:size-24 object-cover"
              [src]="item?.user?.profilePictureUrl" />
            <span
              *ngIf="!item?.user?.profilePictureUrl"
              alt=""
              class="rounded-full text-white size-[60px] md:size-24 object-cover text-[26px] md:text-[36px] bg-black flex items-center justify-center font-bold">
              {{ item?.user?.firstName?.charAt(0) | uppercase
              }}{{ item?.user?.lastName?.charAt(0) | uppercase }}
            </span>
            <lucide-angular
              *ngIf="
                item?.isAllowedForRegister &&
                item?.isAllowed &&
                eventType !== 'past'
              "
              [ngClass]="
                item?.user?.userStatus === 'CHECKEDIN'
                  ? 'bg-[#239782] text-[white]'
                  : 'bg-[#29415F] text-[white]'
              "
              name="check"
              class="checkIcon absolute bottom-0 md:bottom-[10px] right-0 h-5 w-5 p-1 rounded-full text-center"></lucide-angular>
          </div>
          <!-- </div> -->
        </div>
        <div class="w-[75%] md:w-[70%]">
          <h6 class="font-kanit text-[13px] font-bold leading-5 tracking-wide">
            {{ item?.user?.firstName + ' ' + item?.user?.lastName }}
          </h6>
          <p
            class="text-slate-500 dark:text-white text-[12px] font-normal truncate">
            {{ item?.user?.email }}
          </p>
          <p
            class="text-slate-500 dark:text-white text-[12px] font-normal truncate">
            Cost: <strong>{{ item?.originalAmount | formatCurrency }}</strong>
          </p>
          <div class="flex justify-start">
            <div
              *ngIf="
                item?.isAllowedForRegister &&
                item?.isAllowed &&
                eventType !== 'past'
              "
              class="text-center border-b border-[#8d9fb8] dark:border-[#617188] pt-2">
              <!-- <p class="text-xs">OL, DL</p> -->
              <p
                class="dark:text-[#BCC9DD] text-[12px]"
                [ngClass]="
                  isAdminorParent
                    ? 'cursor-pointer'
                    : userDetail.id == item?.user?.id
                      ? 'cursor-pointer'
                      : ''
                "
                (click)="
                  isAdminorParent
                    ? unregister(item?.user?.id)
                    : userDetail.id == item?.user?.id
                      ? unregister(item?.user?.id)
                      : ''
                ">
                Unregister
              </p>
            </div>
            <div
              class="text-center pt-2 ml-3"
              *ngIf="item?.insuranceDocumentUrl && isAdmin">
              <p
                class="dark:text-[#BCC9DD] text-[#678cc3] text-[12px] cursor-pointer"
                (click)="
                  isAdminorParent
                    ? view(item?.insuranceDocumentUrl)
                    : userDetail.id == item?.user?.id
                      ? view(item?.insuranceDocumentUrl)
                      : ''
                ">
                View Insurance
              </p>
            </div>
            <!-- <div class="text-center border-r border-dashed border-[#E2E8F0] dark:border-[#617188] pt-2 px-4">
                                        <p  class="text-xs">4th</p>
                                        <p class="text-[#64748B] text-[10px]">Grade</p>
                                    </div>
                                    <div class="text-center py-2 px-4">
                                        <p  class="text-xs">11.06.13</p>
                                        <p class="text-[#64748B] text-[10px]">DOB</p>
                                    </div>-->
          </div>
          <div *ngIf="item?.paymentPlanStatus" class="cursor-pointer mt-3 px-3 py-2 rounded-sm inline-flex items-center justify-center" [ngClass]="checkPaymentPlanStatus(item?.paymentPlanStatus)['containerClass']" (click)="showPaymentPlanHistoryTransactions(item)">
            <p class="text-[12px]" [ngClass]="checkPaymentPlanStatus(item?.paymentPlanStatus)['labelClass']">
              {{checkPaymentPlanStatus(item?.paymentPlanStatus)['label']}}
            </p>
          </div>
          <div (click)="payInvoice(item?.hostedInvoiceUrl, item)" *ngIf="item?.hostedInvoiceUrl" class="mt-3 px-3 py-2 rounded-sm inline-flex items-center justify-center" [ngClass]="checkInvoiceLinkPaymentStatus(item?.transactionStatus)['containerClass']">
            <p class="text-[12px]" [ngClass]="checkInvoiceLinkPaymentStatus(item?.transactionStatus)['labelClass']">
              {{checkInvoiceLinkPaymentStatus(item?.transactionStatus)['label']}}
            </p>
            <span *ngIf="showTooltip && selectedRegisteredUser?.id === item?.id" class="tooltip show">Link Copied</span>
          </div>
        </div>
      </div>
      <div
        class="text-center dark:bg-[#29415F] bg-[#d3dde7] p-4 absolute top-0 right-0 h-full flex flex-col items-center justify-center rounded-tr-md rounded-br-md">
        <i
          [class]="
            item?.isInsuranceAmountUsed
              ? 'ri-shield-star-fill text-[#247FE9] text-3xl'
              : 'ri-shield-star-fill text-[#000] text-3xl'
          "></i>
        <i
          [class]="
            item?.isSketchplayBucksUsed
              ? 'ri-money-dollar-circle-line text-[#239782] text-3xl'
              : 'ri-money-dollar-circle-line text-[#000] text-3xl'
          "></i>
        <i
          [class]="
            item?.isPackageUsed
              ? 'ri-box-3-line text-[#247FE9] text-3xl'
              : 'ri-box-3-line text-[#000] text-3xl'
          "></i>
      </div>
    </div>
  </div>
</div>
<p class="flex justify-center text-md font-normal color-[#000] dark:color-[#fff]" *ngIf="userData?.length == 0">
  There are no users registered for this event.​
</p>
