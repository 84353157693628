import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, catchError } from 'rxjs';
import { API_ORGANIZATION, API_WEB_SETTING } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class WebSettingsService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  updateWebSettingsContentDetails(payload: any): Observable<any> {
    payload["themeType"] = "theme1";
    return this.http.put<any>(`${environment.API}${API_WEB_SETTING.UPDATE_CONTENT_DETAILS}`, payload)?.pipe(catchError(handleError))
  }

  updateWebSettingsActive(payload: any): Observable<any> {
    payload["themeType"] = "theme1";
    return this.http.put<any>(`${environment.API}${API_WEB_SETTING.UPDATE_WEB_SETTING_ACTIVE}`, payload)?.pipe(catchError(handleError))
  }

  WebSettingsDeactivateClient(payload: any): Observable<any> {
    payload["themeType"] = "theme1";
    return this.http.put<any>(`${environment.API}${API_ORGANIZATION.UPDATE_ORGANIZATION_ACTIVE}`, payload)?.pipe(catchError(handleError))
  }


  getWebSettingsContentDetails(id: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_WEB_SETTING.WEB_SETTING}/${id}`)?.pipe(catchError(handleError))
  }

}
