import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_EMAIL } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor(
    public http: HttpClient,
  ) { }

  getEmailTemplate(): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_EMAIL.GET_EMAIL_TEMPLATE}`)?.pipe(catchError(handleError))
  }

  getTeamNames(orgId: any): Observable<any> {
    let url = `${environment.API}${API_EMAIL.GET_TEAM_NAMES}?skip=0&limit=1000`;
    if (orgId) {
      url += `&orgIds=${orgId}`;
    }
    return this.http.get<any>(url)?.pipe(catchError(handleError))
  }

  getCustomUserList(orgId: any): Observable<any> {
    let url = `${environment.API}${API_EMAIL.CREATE_CUSTOM_LIST}?skip=0&limit=1000`;
    if (orgId) {
      url += `&orgIds=${orgId}`;
    }
    return this.http.get<any>(url)?.pipe(catchError(handleError))
  }

  getLeagueName(orgId?: any): Observable<any> {
    let url = `${environment.API}${API_EMAIL.GET_LEAGUE_NAME}?skip=0&limit=1000`;
    if (orgId) {
      url += `&orgIds=${orgId}`;
    }
    return this.http.get<any>(url)?.pipe(catchError(handleError));
  }

  sendEmail(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EMAIL.SEND_EMAIL}`, payload)?.pipe(catchError(handleError))
  }
  sendTestEmail(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EMAIL.SEND_TEST_EMAIL}`, payload)?.pipe(catchError(handleError))
  }

  createCustomList(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_EMAIL.CREATE_CUSTOM_LIST}`, payload)?.pipe(catchError(handleError))
  }

  getCustomListDetailsById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_EMAIL.GET_CUSTOM_DETAILS_BY_ID}/${id}`)?.pipe(catchError(handleError))
  }

  getTemplateById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_EMAIL.SEND_EMAIL}/${id}`)?.pipe(catchError(handleError))
  }

  deleteCustomUser(customListId: number | string, userIds: number | string): Observable<any> {
    return this.http.delete(`${environment.API}email-campaign/delete/custom-list/users/${customListId}?userIds=${userIds}`)?.pipe(catchError(handleError));
  }

}
