import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_FEATURE, API_TEAM } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
    public http: HttpClient,
  ) { }

  getTeamsList(payload: { orgId: number; skip: number; limit: number; isActive: boolean }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_TEAM.TEAM}?orgId=${payload?.orgId}&skip=${payload?.skip}&limit=${payload?.limit}&isActive=${payload?.isActive}`)?.pipe(catchError(handleError))
  }

  getAllSportPositions(orgId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_TEAM.ALL_POSITION}?orgId=${orgId}`)?.pipe(catchError(handleError));
  }

  createTeam(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_TEAM.TEAM}`, payload)?.pipe(catchError(handleError))
  }

  updateTeam(payload: any, id: number): Observable<any> {
    return this.http.put<any>(`${environment.API}${API_TEAM.TEAM}/${id}`, payload)?.pipe(catchError(handleError))
  }

  createRoster(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_TEAM.TEAM_ROSTER}`, payload)?.pipe(catchError(handleError))
  }

  updateRoster(payload: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}${API_TEAM.TEAM_ROSTER}`, payload)?.pipe(catchError(handleError))
  }

  deleteRoster(parentRosterId: number | string, teamId: number | string): Observable<any> {
    return this.http.delete<any>(`${environment.API}${API_TEAM.DELETE_ROSTER}/${parentRosterId}/${teamId}`)?.pipe(catchError(handleError))
  }

  getTeamDetails(id: number | string, leagueId?: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_TEAM.TEAM}/${id}?leagueId=${leagueId ? leagueId : ''}`)?.pipe(catchError(handleError))
  }

  getRosterDocuments(rosterId: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_TEAM.GET_ROSTER_DOCUMENT}/${rosterId}`)?.pipe(catchError(handleError));
  }

  deleteRosterDocument(rosterId: number | string, docId: number | string): Observable<any> {
    return this.http.delete<any>(`${environment.API}${API_TEAM.DELETE_ROSTER_DOCUMENT}/${rosterId}/${docId}`)?.pipe(catchError(handleError));
  }

  addRosterDocument(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}${API_TEAM.ADD_ROSTER_DOCUMENT}`, payload)?.pipe(catchError(handleError))
  }

  deleteTeam(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.API}${API_TEAM.TEAM}/${id}`)?.pipe(catchError(handleError))
  }

  deleteCoach(teamId: number | string, coachId: number | string): Observable<any> {
    return this.http.delete<any>(`${environment.API}${API_TEAM.DELETE_COACH}/${teamId}/${coachId}`)?.pipe(catchError(handleError));
  }

  deleteManager(teamId: number | string, managerId: number | string): Observable<any> {
    return this.http.delete<any>(`${environment.API}${API_TEAM.DELETE_MANAGER}/${teamId}/${managerId}`)?.pipe(catchError(handleError));
  }

  getAllUserGrades(payload: { skip: number; limit: number; }): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_FEATURE.GET_GRADES}?skip=${payload?.skip}&limit=${payload?.limit}`)?.pipe(catchError(handleError))
  }

}
