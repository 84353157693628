import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { getFileName, showAlertMessage } from '@app/core/helpers/utils';
import { AuthenticationService } from '@app/core/services/auth.service';
import { FileUploadService } from '@app/core/services/fileupload.service';
import { SidebarService } from '@app/core/services/sidebar.service';
import { MDModalModule } from '@app/shared/component/modals';
import { ImageCropperComponent } from '@app/shared/image-cropper/image-cropper.component';
import { setCommunityPayload } from '@app/store/Event/event-reducer';
import { NgSelectModule } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { LucideAngularModule } from 'lucide-angular';
import { NgxMaskDirective } from 'ngx-mask';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-child-community',
  standalone: true,
  imports: [
    CommonModule,
    MDModalModule,
    FormsModule,
    ReactiveFormsModule,
    LucideAngularModule,
    NgSelectModule,
    NgxMaskDirective,
    ImageCropperComponent
  ],
  templateUrl: './child-community.component.html',
  styleUrl: './child-community.component.scss'
})
export class ChildCommunityComponent {

  childCommunityForm!: FormGroup
  orgClients: any[] = []
  isFormSubmitted: boolean = false;
  selectedOrgId: any = null
  rolesData: any[] = []
  roleIds: any[] = []

  imgUploadLoader: boolean = false;
  logoImageFileName: string = '';
  logoImageFile = null;
  imageChangedEvent = null;
  showCropperModal = false;
  aspectRatio = 16 / 9;
  logoImageFileSrc = '';
  isShowChildEvents: boolean = false;
  isShowAccessCode: boolean = false;
  orgId = localStorage.getItem('activeLinkforOrganization');

  @Input() commingFrom = ''
  @Input() selectedChildCommunityData: any
  @Input() isCalender: any
  @Input() eventParamOrgId: any
  @Output() closeModal = new EventEmitter<any>()
  @ViewChild('logoImageInput') logoImageInput!: ElementRef;

  constructor(
    private authenticationService: AuthenticationService,
    private sidebarService: SidebarService,
    private ngxSpinnerService: NgxSpinnerService,
    private fileUploadService: FileUploadService,
    private store: Store
  ) {

  }

  ngOnInit(): void {
    this.childCommunityForm = new FormGroup({
      communityName: new FormControl('', [
        Validators.required, Validators.pattern(/\S.*/)
      ]),
      accessCode: new FormControl(''),
      parentCommunity: new FormControl(null, [Validators.required]),
      communityLogo: new FormControl(''),
    });
    this.userMe()
  }

  userMe() {
    this.authenticationService.userMe().subscribe((response: any) => {
      if (response?.status) {
        let allOrgs = response?.data?.organizations;
        this.orgClients = allOrgs?.filter((item: any) => item.roles.some((role: any) => role?.type === "admin"));
        if (this.commingFrom === 'createEvent') {
          if (this.selectedChildCommunityData) {
            this.setCommunityData()
          }
          if (this.orgId) {
            this.setParentCommunity(this.isCalender ? this.eventParamOrgId : Number(this.orgId))
          }
        } else if (this.commingFrom === 'editCommunity') {
          if (this.selectedChildCommunityData) {
            this.setCommunityData()
            if (this.selectedChildCommunityData?.parentOrg?.id) {
              this.setParentCommunity(this.isCalender ? this.eventParamOrgId : this.selectedChildCommunityData?.parentOrg?.id)
            }
          }
        }
      }
    })
  }

  get communityName() {
    return this.childCommunityForm && this.childCommunityForm.get('communityName')!;
  }
  get accessCode() {
    return this.childCommunityForm && this.childCommunityForm.get('accessCode')!;
  }
  get parentCommunity() {
    return this.childCommunityForm && this.childCommunityForm.get('parentCommunity')!;
  }

  get communityLogo() {
    return this.childCommunityForm && this.childCommunityForm.get('communityLogo')!;
  }

  setCommunityData() {
    this.isShowChildEvents = this.selectedChildCommunityData?.showChildEvents
    this.logoImageFileSrc = this.selectedChildCommunityData?.primaryLogo
    this.logoImageFileName = this.selectedChildCommunityData?.primaryLogo ? getFileName(this.selectedChildCommunityData?.primaryLogo) : '';
    this.childCommunityForm.patchValue({
      accessCode: this.selectedChildCommunityData?.accesscode,
      communityName: this.selectedChildCommunityData?.name
    })
  }

  setParentCommunity(orgId: number) {
    const org = this.orgClients?.find(x => x.id === orgId)
    if (this.commingFrom === 'editCommunity') {
      this.childCommunityForm.controls['accessCode'].setValidators(
        Validators.compose([Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),]));
    } else {
      this.isShowAccessCode = org?.isPublic
      if (org?.isPublic) {
        this.childCommunityForm.get('accessCode')?.setErrors(null);
      } else {
        this.childCommunityForm.controls['accessCode'].setValidators(
          Validators.compose([Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),]));
      }
    }

    this.parentCommunity.setValue(orgId)
    this.parentCommunity.updateValueAndValidity()
    this.childCommunityForm.updateValueAndValidity()
  }

  handleSubmit() {
    if (this.childCommunityForm.invalid) {
      for (const control of Object.keys(this.childCommunityForm.controls)) {
        this.childCommunityForm.controls[control].markAsTouched();
      }
      this.isFormSubmitted = true;
      return;
    }
    this.isFormSubmitted = false;
    this.saveCommunity();
  }

  handleCommunityChange(event: any) {
    this.isShowAccessCode = event?.isPublic
    if (event?.isPublic) {
      this.childCommunityForm.get('accessCode')?.setErrors(null);
    } else {
      this.childCommunityForm.controls['accessCode'].setValidators(
        Validators.compose([Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),]));
    }
    this.childCommunityForm.updateValueAndValidity()
  }

  saveCommunity() {
    const commonPayload = {
      name: this.communityName.value,
      accesscode: this.accessCode.value ? this.accessCode?.value?.toUpperCase() : '',
      parentOrgId: this.parentCommunity.value,
      primaryLogo: this.logoImageFileSrc,
      showChildEvents: this.isShowChildEvents
    };
    if (this.commingFrom === 'createEvent') {
      this.store.dispatch(setCommunityPayload({ payload: commonPayload }));
      this.handleCloseModal(true)
    } else if (this.commingFrom === 'editCommunity') {
      this.ngxSpinnerService.show();
      delete commonPayload.parentOrgId
      this.sidebarService.updateCommunity(this.selectedChildCommunityData?.id, commonPayload).subscribe((response: any) => {
        this.ngxSpinnerService.hide();
        if (response?.status) {
          this.handleCloseModal(true, response)
        } else {
          showAlertMessage(response.message ?? "Something went wrong!", "error");
        }
      }, (error) => {
        this.ngxSpinnerService.hide();
      })
    } else {
      this.ngxSpinnerService.show();
      this.sidebarService.addChildCommunity(commonPayload).subscribe((response: any) => {
        this.ngxSpinnerService.hide();
        if (response?.status) {
          this.handleCloseModal(true, response)
        } else {
          showAlertMessage(response.message ?? "Something went wrong!", "error");
        }
      }, (error) => {
        this.ngxSpinnerService.hide();
      })
    }
  }

  handleCloseModal(isSubmit: boolean, response: any = null) {
    this.selectedOrgId = null
    this.roleIds = []
    const resPayload = {
      isSubmit: isSubmit,
      response: response
    }
    this.closeModal.emit(resPayload)
  }

  handleChangeImage(event: any) {
    const file = event.target.files[0];
    this.imageChangedEvent = event;
    if (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
      const filename = file?.name ?? "hero-background.jpg";
      this.setCropperModalHideShow(true);
      this.aspectRatio = 1 / 1;
      this.logoImageFileName = filename;
      this.logoImageFile = file;
    } else {
      event.target.value = null;
      showAlertMessage("Please select a JPG, or PNG file", "error");
    }
  }

  setCropperModalHideShow(flag: boolean) {
    this.showCropperModal = flag;
  }

  clearFileUploadInputValues() {
    this.handleClear()
  }

  handleClear() {
    this.childCommunityForm.patchValue({
      communityLogo: ''
    });
    this.logoImageFileName = '';
    this.setCropperModalHideShow(false);
    this.logoImageInput.nativeElement.value = '';
    this.imgUploadLoader = false
    this.logoImageFileSrc = ''
  }

  setCropperImgVal(croppImgFile: any) {
    this.logoImageFile = croppImgFile.detail;
  }

  handleSaveModal() {
    this.setCropperModalHideShow(false);
    this.uploadImgFile(this.logoImageFile);
  }

  uploadImgFile(file: File | null) {
    this.imgUploadLoader = true;
    this.fileUploadService.uploadFile(file).subscribe((response: any) => {
      if (response?.status && response?.data) {
        this.imgUploadLoader = false;
        this.logoImageFileSrc = response?.data;
      } else {
        this.imgUploadLoader = false;
      }
    });
  }

  handleChangeToggle(event: any) {
    this.isShowChildEvents = event.target.checked
  }

  uppercaseInput(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
  }


}
