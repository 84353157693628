// confirmation.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SuccessErrorService {
  private successErrorSubject = new Subject<any>();

  getSuccessError() {
    return this.successErrorSubject.asObservable();
  }

  confirmSuccess(title: string, message: string, imageUrl: string, onConfirm: () => void, onCancel: () => void, confirmButtonText: string = 'Yes', cancelButtonText: string = 'Cancel') {
    this.successErrorSubject.next({ title, message, imageUrl, onConfirm, onCancel, confirmButtonText, cancelButtonText });
  }

  close() {
    this.successErrorSubject.next(null);
  }
}
