import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Observable, catchError, from } from 'rxjs';
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../../store/Authentication/auth.models';
import { API_AUTH } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';


@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  user!: User;

  constructor(private http: HttpClient) {
  }

  /**
   * Returns the current user
   */
  public currentUser(): User {
    return getFirebaseBackend().getAuthenticatedUser();
  }


  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {

    return from(getFirebaseBackend().loginUser(email, password).then(user => {
      return user;
    })
    );
  }
  userLogin(res: any): Observable<any> {
    return this.http.post(environment.API + API_AUTH.LOGIN, res)?.pipe(catchError(handleError));
  }
  forgotPassword(res: any): Observable<any> {
    return this.http.post(environment.API + API_AUTH.FORGOT_PASSWORD, res)?.pipe(catchError(handleError));
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */

  registerData(res: any): Observable<any> {
    return this.http.post(environment.API + API_AUTH.SIGN_UP, res)?.pipe(catchError(handleError));
  }
  enterOtp(res: any): Observable<any> {
    return this.http.post(environment.API + API_AUTH.ACCOUNT_VERIFICATION, res)?.pipe(catchError(handleError));
  }
  checkEmail(res: any): Observable<any> {
    return this.http.post(environment.API + API_AUTH.CHECK_EMAIL, res)?.pipe(catchError(handleError));
  }
  checkAccessCode(code: any): Observable<any> {
    return this.http.get(environment.API + API_AUTH.CHECK_ACCESS_CODE + '?accesscode=' + code)?.pipe(catchError(handleError));
  }
  register(email: any, username: any, password: any) {
    return from(getFirebaseBackend().registerUser(email, username, password).then((response: any) => {
      const user = response;
      return user;
    }));
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return getFirebaseBackend().forgetPassword(email).then((response: any) => {
      const message = response.data;
      return message;
    });
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    // getFirebaseBackend().logout();
  }

  resendOtp(res: any): Observable<any> {
    return this.http.post(environment.API + API_AUTH.RESEND_OTP, res)?.pipe(catchError(handleError));
  }

  changePassword(payload: object): Observable<any> {
    return this.http.post(environment.API + API_AUTH.CHANGE_PASSWORD, payload)?.pipe(catchError(handleError));
    // getFirebaseBackend().logout(); // no need of this line for logout.
  }

  userMe(): Observable<any> {
    // let token = localStorage.getItem('token')
    // const headers = new HttpHeaders({
    //     'Authorization': `Bearer ${token}`
    // });
    // const options = { headers: headers };
    return this.http.get(environment.API + API_AUTH.GET_ME)?.pipe(catchError(handleError));
  }

  refreshToken(res: any): Observable<any> {
    return this.http.post(environment.API + API_AUTH.REFRESH_TOKEN, res)?.pipe(catchError(handleError));
  }
}

