// import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfirmationService } from './alert-popup.service';

@Component({
  selector: 'app-alert-popup',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.css']
})

export class AlertPopupComponent implements OnInit, OnDestroy {
  confirmation: any;
  private subscription !: Subscription;

  constructor(private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.subscription = this.confirmationService.getConfirmation().subscribe((confirmation) => {
      this.confirmation = confirmation;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  confirm() {
    if (this.confirmation && this.confirmation.onConfirm) {
      this.confirmation.onConfirm();
    }
    this.close();
  }

  cancel() {
    if (this.confirmation && this.confirmation.onCancel) {
      this.confirmation.onCancel();
    }
    this.close();
  }

  close() {
    this.confirmationService.close();
  }
}
