import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { EventInvoiceLinkPaymentStatus, IPaymentPlanPaymentStatus } from '@app/core/helpers/constants';
import { checkIfUserIsAdmin, checkIfUserIsAdminOrParent, parseDateWithoutTimezone } from '@app/core/helpers/utils';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { EventService } from '@app/core/services/event.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { UserdataService } from '@app/core/services/userdata.service';
import { SwalService } from '@app/layouts/sidebar/swalService';
import { DrawerModule } from '@app/shared/component/drawer';
import { ConfirmationService } from '@app/shared/component/drawer-popup/alert-popup/alert-popup.service';
import { SpinnerService } from '@app/shared/component/drawer-popup/spinner/spinner.service';
import { SuccessErrorService } from '@app/shared/component/drawer-popup/success-error/success-error-popup/success-error-popup.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'app-view-register-users',
  standalone: true,
  imports: [DrawerModule, LucideAngularModule, CommonModule, NgSelectModule, FormsModule, PipesModule],
  templateUrl: './view-register-users.component.html',
  styleUrl: './view-register-users.component.scss'
})
export class ViewRegisterUsersComponent implements OnInit, OnChanges {

  // @ViewChild('uesrDrawer') uesrDrawer: CustomDrawerComponent | any;
  @Input() id: any
  @Input() data: any
  @Input() isCalender: any;
  @Input() isManageUser: any;
  @Input() userId: any;
  @Input() isComingFromProfile: boolean = false;
  @Input() isViewDuplicateUsers: any;
  @Input() dates: any
  @Input() type: any
  @Input() eventType: any;
  @Input() purchaseHistoryOrgId!: any;
  userData: any;
  sprintDates: any = [];
  selectedSprintDate: any;
  userDetail: any;
  isAdminorParent: boolean = false;
  isAdmin: boolean = false;
  purchaseHistory: boolean = false;
  showTooltip: boolean = false;
  selectedRegisteredUser: any = null;
  @Output() setEventId = new EventEmitter<any>()
  @Output() setUserDataLength = new EventEmitter<any>()
  @Output() comeFromPurchaseHistory = new EventEmitter<any>()

  constructor(private campService: EventService,
    private swalService: SwalService,
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService,
    private successErrorService: SuccessErrorService,
    private userdataService: UserdataService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private location: Location
  ) {
    this.userdataService.userData$.subscribe(userData => {
      this.userDetail = userData;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {

    this.sprintDates = this.dates?.map((date: any) => {
      return {
        ...date,
        labelledDate: parseDateWithoutTimezone(date?.startDate, 'dddd, MMMM D, YYYY') //format(date?.startDate, 'EEEE, MMMM d, yyyy')
      }
    });
    if (this.sprintDates) {
      this.selectedSprintDate = this.sprintDates[0]?.id;
    }

  }
  ngOnInit(): void {
    this.getUserList(this.selectedSprintDate ?? this.id);
    const user = this.localStorageService.getSaveData('userDetailsFromUserMe');
    let orgId = this.localStorageService.getSaveData("activeLinkforOrganization");
    if (this.purchaseHistoryOrgId) {
      orgId = this.purchaseHistoryOrgId;
    }
    this.isAdminorParent = checkIfUserIsAdminOrParent(user, orgId);
    this.isAdmin = checkIfUserIsAdmin(user, orgId);
    let pathname = window.location.pathname;
    if (pathname.includes('purchase-history')) {
      this.purchaseHistory = true;
    } else {
      this.purchaseHistory = false;
    }
  }
  onChangeDate(event: any) {
    this.getUserList(this.selectedSprintDate ?? this.id);
    this.setEventId.emit(this.selectedSprintDate)
  }
  getUserList(id: any) {
    this.spinnerService.show();
    this.campService.getAtleteUserList(id).subscribe(
      (response) => {
        this.userData = response?.data;
        this.setUserDataLength.emit(response?.data?.length)
        this.spinnerService.hide();
      }, (error) => {
        this.spinnerService.hide();
      })
  }
  checkIn(userId: any, status: any) {

    this.confirmationService.confirm(
      status !== 'CHECKEDIN' ? 'Are you sure you want to check this user in?' : 'Are you sure you want to unregister this user?',
      status === 'CHECKEDIN' ? 'If you’ve purchased insurance for this event, please refer to the email communication provided by the insurance provider during registration.' : '',
      () => {
        this.spinnerService.show();
        const paylod = {
          event: this.type == 'daily' ? this.selectedSprintDate : this.data.id,
          user: userId,
          date: this.data?.startDate,
          time: this.data?.startTime
        }
        this.campService.checkInCheckOut(paylod, status).subscribe(
          (response) => {
            if (response?.status) {
              this.spinnerService.hide();
              this.successErrorService.confirmSuccess(
                response?.message,
                'Tap anywhere to continue.',
                'assets/images/success@2x.png',
                () => {
                  this.getUserList(this.type == 'daily' ? this.selectedSprintDate : this.data.id);
                },
                () => {
                  // confirmSuccess cancel callback
                },
                'success',
                'Cancel'
              );
            }
          }, (error) => {
            this.spinnerService.hide();
          });
      },
      () => {
        // onCancel callback
      },
      status !== 'CHECKEDIN' ? 'Yes, Check-in' : 'Yes, Unregister',
      'Cancel'
    );
  }

  unregister(userId: any) {

    this.confirmationService.confirm(
      'Are you sure you want to unregister this user?',
      'If you’ve purchased insurance for this event, please refer to the email communication provided by the insurance provider during registration.',
      () => {
        this.spinnerService.show();
        const paylod = {
          event: this.type == 'daily' ? this.selectedSprintDate : this.data.id,
          user: userId,
          date: this.data?.startDate,
          time: this.data?.startTime
        }
        this.campService.unregisterUSer(paylod).subscribe(
          (response) => {
            if (response?.status) {
              this.spinnerService.hide();
              this.successErrorService.confirmSuccess(
                response?.message,
                'Tap anywhere to continue.',
                'assets/images/success@2x.png',
                () => {
                  this.getUserList(this.type == 'daily' ? this.selectedSprintDate : this.data.id);
                },
                () => {
                  // confirmSuccess cancel callback
                },
                'success',
                'Cancel'
              );
            }
          }, (error) => {
            this.spinnerService.hide();
          });
      },
      () => {
        // onCancel callback
      },
      'Yes, Unregister',
      'Cancel'
    );
  }

  view(data: any) {
    window.open(data, '_blank');
  }

  checkPaymentPlanStatus(status: string) {
    if (status === IPaymentPlanPaymentStatus.ACTIVE_PLAN) {
      return {
        label: 'Active Plan',
        containerClass: 'bg-[#FFAB0033]',
        labelClass: 'text-[#FFAB00]'
      }
    } else if (status === IPaymentPlanPaymentStatus.PLAN_COMPLETED) {
      return {
        label: 'Plan Completed',
        containerClass: 'bg-[#4CAA9933]',
        labelClass: 'text-[#249782]'
      }
    } else if (status === IPaymentPlanPaymentStatus.INSTALLMENT_FAILED) {
      return {
        label: 'Installment Failed',
        containerClass: 'bg-[#EF444433]',
        labelClass: 'text-[#ef4444]'
      }
    } else {
      return {
        label: 'Plan Canceled',
        containerClass: 'bg-[#000]',
        labelClass: 'text-[#fff]'
      }
    }
  }

  checkInvoiceLinkPaymentStatus(status: string) {
    if (status === EventInvoiceLinkPaymentStatus.INVOICE_SENT) {
      return {
        label: 'Invoice Sent',
        containerClass: 'bg-[#FFAB0033] cursor-pointer',
        labelClass: 'text-[#FFAB00]'
      }
    } else {
      return {
        label: 'Paid',
        containerClass: 'bg-[#4CAA9933]',
        labelClass: 'text-[#249782]'
      }
    }
  }

  showPaymentPlanHistoryTransactions(item: any) {
    this.router.navigate(['/payment-plans-transactions'], {
      queryParams: {
        productName: '',
        firstName: item?.user?.firstName,
        lastName: item?.user?.lastName,
        paymentId: item?.paymentPlanHistoryId,
        purchaseDate: item?.paymentPlanPurchaseDate,
        eventName: item?.event?.name,
        paymentPlanName: item?.paymentPlanName,
        planCost: item?.paymentPlanCost ?? item?.paymentPlanOriginalAmount ?? 0,
        depositAmount: item?.paymentPlanDepositAmount,
        eventId: item?.event?.id,
        showViewRegisteredUsers: true,
        pathname: window.location.pathname,
        purchaseHistory: this.purchaseHistory,
        orgId: item?.organization?.id,
        eventOrgId: item?.organization?.id,
        isCalender: this.isCalender,
        isViewDuplicateUsers: this.isCalender ? this.isViewDuplicateUsers : false,
        isAdmin: this.isAdmin,
        eventType: this.eventType,
        isManageUser: this.isManageUser,
        userId: this.isManageUser ? this.userId : '',
        profile: this.isComingFromProfile,
      }
    });
    this.comeFromPurchaseHistory.emit(true);
  }

  payInvoice(url: string, item: any) {
    if (item?.transactionStatus === EventInvoiceLinkPaymentStatus.INVOICE_SENT) {
      this.selectedRegisteredUser = item;
      navigator.clipboard.writeText(url).then(() => {
        this.showTooltip = true;
        setTimeout(() => {
          this.showTooltip = false;
          // window.open(url, '_blank');
        }, 1000);
      }).catch(err => {
        console.error('Failed to copy:', err);
      });
    }
  }
}
